const Text = {
    is: `
# Vinnslusamningur Tilkynna
Þessi vinnslusamningur setur fram skilmála og skilyrði varðandi vinnslu persónuupplýsinga af Tilkynna og / eða undirvinnsluaðila Tilkynna. Tilkynna er heimilt að vinna með persónuupplýsingar um lögaðila, í tengslum við þjónustu við lögaðila samkvæmt samningnum er, og getur því talist vinnsluaðili í skilningi reglugerðar Evrópuþingsins og Evrópuráðs frá 27. apríl 2016, um vernd einstaklinga með tilliti til vinnslu persónuupplýsinga og um frjálsan flutning slíkra gagna. 

Þessi samningur getur verið uppfærður og verður þá tilkynning um uppfærsluna send á tölvupóst viðkomandi lögaðila.

Lögaðili, með staðfestu á Íslandi, hér eftir nefndur **„ábyrgðaraðili“** sem hefur sótt um þjónustu Tilkynna í gegnum vefsíðu Tilkynna.is

OG

Slidesome ehf, með staðfestu á Íslandi, hér eftir nefndur **„vinnsluaðili“**,

gera með sér svofelldan vinnslusamning, í samræmi við 28. gr. reglugerðar Evrópuþingsins og ráðsins (ESB) 2016/679 frá 27. apríl 2016: 


## 1. Tilgangur samnings 
Tilgangur þessara samningsákvæða er að tilgreina þær skyldur sem vinnsluaðili sinnir f.h. ábyrgðaraðila, í tengslum við þá vinnslustarfsemi sem samningurinn tekur til, sjá nánar í kafla 4 um *Skyldur vinnsluaðila gagnvart ábyrgðaraðila*.

Samningsaðilar skulu bundnir af öllum viðeigandi lagaákvæðum sem varða vinnslu persónuupplýsinga hjá þeim og þá sérstaklega reglugerð Evrópuþingsins og ráðsins (ESB) 2016/679, frá 27. apríl 2016, um vernd einstaklinga í tengslum við vinnslu persónuupplýsinga og um frjálsa miðlun slíkra upplýsinga og niðurfellingu tilskipunar 95/46/EB (almennu persónuverndarreglugerðinni) sem kemur/kom til framkvæmda 25. maí 2018.


## 2. Lýsing á þeirri vinnslu sem samið er um að vinnsluaðili sinni

Vinnsluaðila er heimilt að vinna, f.h. ábyrgðaraðila, þær persónuupplýsingar sem eru honum nauðsynlegar til að veita eftirfarandi þjónustu

Eðli þeirrar vinnslustarfsemi sem hér um ræðir er að taka á móti tilkynningum og miðla þeim til lögaðila.

Vinnsluaðila er heimilt að vinna með eftirfarandi tegundir persónuupplýsinga sem ábyrgðaraðili veitir:
- Nafn, kennitala, heimilisfang og netfang ábyrgðaaðila
- Tilkynningasvæði sem eru frátekin fyrir ábyrgðaraðila
- Áskriftarleið sem ábyrgðaraðili hefur valið

Vinnsluaðila er heimilt að vinna með eftirfarandi flokka af skráðum einstaklingum:
- Ábyrgðaraðili
- Notendur ábyrgðaraðila
- Notendur sem eru boðnir inn á tilkynningu fyrir hönd ábyrgðaraðila

Til að vinnsluaðili geti veitt umbeðna þjónustu, skal ábyrgðaraðili veita vinnsluaðila eftirfarandi upplýsingar
- Nafn, kennitala, heimilisfang og netfang ábyrgðaaðila
- Tilkynningasvæði sem ábyrgðaraðili notar
- Áskriftarleið sem ábyrgðaraðili hefur valið


## 3. Gildistími samnings

Samningur þessi tekur gildi þegar ábyrgðaraðili samþykkir skilmála Tilkynna við nýskráningu að þjónustunni og gildir á meðan ábyrgðaraðili nýtir sér þjónustu Tilkynna með einhverjum hætti.


## 4. Skyldur vinnsluaðila gagnvart ábyrgðaraðila

Vinnsluaðili hefur undirritað vinnslusamninga við undirvinnsluaðila sem kunna að meðhöndla persónuupplýsingar fyrir okkar hönd.

### Vinnsluaðili skal:
* eingöngu vinna persónuupplýsingar í samræmi við tilgang vinnslunnar, skv. samningi þessum  
* eingöngu vinna persónuupplýsingar samkvæmt skriflegum fyrirmælum ábyrgðaraðila, sem fylgja samningi þessum. Í þeim tilvikum þegar vinnsluaðili telur að fyrirmæli ábyrgðaraðila samrýmist ekki almennu persónuverndarreglugerðinni eða öðrum viðeigandi lagaákvæðum sem varða vinnslu persónuupplýsinga ber honum að tilkynna ábyrgðaraðilanum slíkt án tafar. Þá skal vinnsluaðili gera ábyrgðaraðila viðvart ef vinnsluaðila er skylt samkvæmt lögum að flytja persónuupplýsingar til þriðju landa eða alþjóðastofnana, nema lög banni að upplýst sé um slíkt.  
* tryggja trúnað um vinnslu þeirra persónuupplýsinga sem þessi samningur tekur til, og 
* tryggja að þeir starfsmenn sem hafi aðgang að persónuupplýsingum í tengslum við framkvæmd samningsins hafi undirritað trúnaðaryfirlýsingu eða séu bundnir þagnarskyldu samkvæmt lögum og að þeir fái viðeigandi þjálfun í vernd persónuupplýsinga. 
* gæta þess að tæki og tól, vörur, forrit og þjónusta séu hönnuð með innbyggða og sjálfgefna persónuvernd að leiðarljósi.

### Notkun á undirvinnsluaðila:
* Vinnsluaðila er heimilt að semja við annan aðila („undirvinnsluaðila“) um að framkvæma tilteknar vinnsluaðgerðir. Áður en ætlaðar breytingar taka gildi, bæði þegar bætt er við undirvinnsluaðila og þegar gerðar eru breytingar á þeim undirvinnsluaðilum sem þegar eru notaðir, eða þegar um að ræða viðbætur eða breytingu á gildandi fyrirkomulagi vinnsluaðgerða, skal vinnsluaðili upplýsa ábyrgðaraðila skriflega um breytingarnar. Þar skal sérstaklega taka fram hvaða vinnsluaðgerðir undirvinnsluaðilinn hyggst taka að sér, nafn og samskiptaupplýsingar undirvinnsluaðilans ásamt dagsetningu samnings. Í viðauka 1 er að finna lista yfir núverandi undirvinnsluaðila.

### Réttur hinna skráðu til upplýsinga.

* Ábyrgðaraðili ber ábyrgð á því að veita hinum skráðu upplýsingar (fræðslu) um vinnslustarfsemina fyrir eða um leið og vinnsla hefst, í samræmi við ákvæði almennu persónuverndarreglugerðarinnar um upplýsingar sem ber að veita hinum skráða, sbr. m.a. 13. og 14. gr. hennar.

### Veiting réttinda til handa hinum skráðu

Að því marki sem hægt er ber vinnsluaðila að aðstoða ábyrgðaraðila við að sinna þeirri skyldu sinni að bregðast við erindum skráðra einstaklinga vegna réttinda þeirra, svo sem vegna aðgangsréttar, réttar til leiðréttingar og eyðingar upplýsinga og til að andmæla vinnslu eða takmarka hana, flutningsréttar og réttar til að þurfa ekki að sæta sjálfvirkri ákvarðanatöku, þ.m.t. notkun persónusniða. Þegar hinn skráði leggur fram beiðni um að neyta réttinda sinna hjá vinnsluaðila skal vinnsluaðilinn áframsenda slíka beiðni án tafar til ábyrgðaraðila.

### Tilkynning vegna öryggisbrots

Vinnsluaðili skal tilkynna ábyrgðaraðila með símtali, tölvupósti eða öðrum leiðum um hvers konar öryggisbrot eigi síðar en 24 klukkustundum eftir að hann verður var við brotið. Með tilkynningunni skulu fylgja hver þau skjöl eða gögn sem nauðsynleg eru til þess að ábyrgðaraðili geti tilkynnt um brotið til viðeigandi eftirlitsstofnunar (Persónuverndar). 

Upplýsingar sem sendar eru hinum skráðu skulu vera á skýru og einföldu máli og lýsa í það minnsta:
- eðli öryggisbrotsins, þ.m.t., þegar það á við, flokkum og gróflega áætluðum fjölda þeirra einstaklinga sem verða fyrir áhrifum af brotinu og flokkum og magni þeirra gagna (e. records) sem um ræðir,
nafni og samskiptaupplýsingum persónuverndarfulltrúa eða annars tengiliðar þar sem hægt er að nálgast frekari upplýsingar,
- hverjar séu líklegar afleiðingar öryggisbrotsins,
- til hvaða aðgerða hafi verið gripið eða lagt til að gripið verði til til að bregðast við brotinu, þ.m.t., þar sem það á við, aðgerða til að draga úr áhrifum brotsins á einstaklinga, 
- til hvaða aðgerða einstaklingarnir geti gripið til að lágmarka tjón sitt, t.a.m. að skipta um lykilorð. 

### Aðstoð gagnvart ábyrgðaraðila við að uppfylla skilyrði almennu persónuverndarreglugerðarinnar

Vinnsluaðili skal:
* aðstoða ábyrgðaraðila við að framkvæma mat á áhrifum á persónuvernd.
* aðstoða ábyrgðaraðila við að uppfylla ákvæði reglugerðarinnar um fyrirframsamráð við eftirlitsyfirvaldið (Persónuvernd

### Öryggisráðstafanir

Vinnsluaðili skal innleiða eftirfarandi öryggisráðstafanir:
- dulkóðun persónuupplýsinga notenda
- koma í veg fyrir að persónuupplýsingar eyðileggist, glatist eða skemmist fyrir slysni, eða með ólögmætum hætti
- rekstur rekstur net- og upplýsingakerfa séu ISO/IEC 27001 vottaðar
- stýringu aðgengis starfsmanna og annarra að kerfum sem hafa að geyma persónu­upplýsingar

### Hvað verður um persónuupplýsingar við lok vinnslu

Þegar þjónustu lýkur samkvæmt samningi þessum samþykkir vinnsluaðili að skila öllum persónugreinanlegum upplýsingum til ábyrgðaraðila og eyða þeim.

Þegar upplýsingum er skilað þarf einnig að eyða öllum afritum af persónugreinanlegum upplýsingum sem finna má í kerfum vinnsluaðila. Þegar upplýsingum hefur verið eytt skal vinnsluaðili sýna fram á það skriflega. 


### Persónuverndarfulltrúi

Vinnsluaðili skal senda ábyrgðaraðila upplýsingar um nafn og samskiptaupplýsingar persónuverndarfulltrúa síns, ef hann hefur verið tilnefndur, sbr. 37. gr. reglugerðarinnar.

### Skrá yfir vinnslustarfsemi

Vinnsluaðili skal halda skrá yfir alla vinnslustarfsemi sem fram fer fyrir ábyrgðaraðila. Í henni skal koma fram eftirfarandi:
- heiti og samskiptaupplýsingar vinnsluaðila, eins eða fleiri, og sérhvers ábyrgðaraðila sem vinnsluaðilinn starfar í umboði fyrir og, eftir atvikum, fulltrúa ábyrgðaraðila eða vinnsluaðila og persónuverndarfulltrúa,
- flokkar vinnslu sem fram fer fyrir hönd hvers ábyrgðaraðila,


### Skjölun vegna sönnunar á reglufylgni

Vinnsluaðili skal útvega ábyrgðaraðila öll nauðsynleg skjöl til að hann geti sýnt fram á reglufylgni og til að ábyrgðaraðili eða úttektaraðili geti framkvæmt úttektir, þ.m.t. skoðanir, og veita aðstoð við slíkar úttektir. 


## 5. Skyldur ábyrgðaraðila gagnvart vinnsluaðila

Ábyrgðaraðili skal: 
- afhenda vinnsluaðila þau gögn sem nefnd eru í kafla II.
- skrá skriflega öll fyrirmæli varðandi vinnsluna sem beint er að vinnsluaðila.
- tryggja, fyrir og á meðan á vinnslu stendur, að hann starfi í samræmi við þær kröfur sem gerðar eru til hans samkvæmt almennu persónuverndarreglugerðinni, og
- hafa yfirumsjón með vinnslunni, þ.m.t. með því að framkvæma úttektir og skoðanir hjá vinnsluaðilanum. 



## Viðauki 1 - undirvinnsluaðilar
Tilkynna notar Amazon Web Services (AWS) hjá Írlandi til að hýsa vefsíðuna, þjónustur og gögn.

[Vinnslusamningur AWS](https://d1.awsstatic.com/legal/aws-gdpr/AWS_GDPR_DPA.pdf)

`,
    en: `


`,
};

export default Text;
