import React from 'react'
import DataProcessingAgreement from '../../../../components/Legal/Organisation/DataProcessingAgreement'

const Page = ({ location }) => {
    return (
        <DataProcessingAgreement
            language="is"
            location={location}
        />
    )
}

export default Page
