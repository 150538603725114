import React from 'react'
import ReactMarkdown from 'react-markdown'
import Layout from '../../Layout'
import SEO from '../../seo'
import localization from '../../../localization'
import LegalNav from './LegalNav'

import DataProcessingAgreementText from './Text/DataProcessingAgreementText'
import { legal } from '../index.module.scss'
import SubpageHero from '../../SubpageHero'

const DataProcessingAgreement = ({ language, location }) => {
    return (
        <>
            <SEO
                title={localization[language].terms.dpa}
                description={localization[language].terms.dpa}
            />
            <Layout isFrontpage>
                <SubpageHero>
                    <div>
                        <h1>{localization[language].terms.dpa}</h1>
                        <LegalNav language={language} location={location} />
                    </div>
                </SubpageHero>
                <div className={legal}>
                    <ReactMarkdown
                        children={DataProcessingAgreementText['is']}
                        allowDangerousHtml={true}
                        escapeHtml={false}
                        skipHtml={false}
                        linkTarget="_blank"
                    />
                </div>
            </Layout>
        </>
    )
}

export default React.memo(DataProcessingAgreement)
